













































































































































































































































































































































































































































































































































// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';
import ConfirmActionDialog from '@/components/app/ConfirmActionDialog.vue';
import InfoDialog from '@/components/app/InfoDialog.vue';

// --- Models ---
import { LinkedUser } from '@/models/User.model';

// --- Services ---
import AppService from '@/services/app';
import UserService from '@/services/user';

// --- Third Party ---
import dayjs from 'dayjs';
import dayJSRelativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(dayJSRelativeTime);

@Component({
  components: {
    ConfirmActionDialog,
    InfoDialog,
  },
})
export default class UserListing extends Vue {

  // used by Filter Area
  search: string = '';

  // used by No Data Slot
  isLoading: boolean = true;

  // used by Item Dialog for Create
  showItemDialog: boolean = false;
  itemDialogAvailable: boolean = false;
  title: string = '';
  clickedItem: LinkedUser = {
    id: 0,
    userTypeId: 0,
    userType: '',
    registrationStatusId: 0,
    registrationStatus: '',
    suspended: false,
    updatedAt: '',

    firstName: '',
    lastName: '',
    email: '',
  };

  valid: boolean = false;
  clicked: boolean = false;

  rules = {
    required: (value: string) => !!value || '',
    requiredNumber: (value: number) => value != null && value !== 0 || '',
    name: (value: string) => /^(?=.*[a-zA-Z- ])[a-zA-Z- ]+$/.test(value) || 'Remove invalid characters',
    email: (value: string) => /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value) || 'E-mail must be valid',
  };

  showInfoDialog: boolean = false;
  infoDialogAvailable: boolean = false;
  infoDialogTitle: string = '';
  infoDialogContent: string = '';

  // used by Confirm Action Dialog for Resend Invite / Suspend / Activate / Delete Item
  action: string = '';
  dialogContent: string = '';
  confirmActionDelay: boolean = false;
  showConfirmActionDialog: boolean = false;
  confirmActionDialogAvailable: boolean = false;

  // #region Functions to display list data on page load
    headers() {
      return [
        {
          value: 'status',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Cellphone',
          value: 'cellphoneNumber',
        },
        {
          text: 'Last Login',
          value: 'lastLogin',
        },
        {
          sortable: false,
          text: '',
          value: 'action',
          align: 'end',
        },
      ];
    }

    customFilter(column: string, search: string, item: LinkedUser) {
      let result: boolean = false;
      
      if (search === '') {
        result = true;
      } else {
        result = item.firstName.toLowerCase().includes(search.toLowerCase()) ||
          item.lastName.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase()) ||
          (item.cellphoneNumber ? item.cellphoneNumber.toLowerCase().includes(search.toLowerCase()) : false) ||
          
          (item.userTypeId === 1 && 
            (
              'a'.includes(search.toLowerCase()) || 
              'ad'.includes(search.toLowerCase()) || 
              'adm'.includes(search.toLowerCase()) || 
              'admin'.includes(search.toLowerCase())
            )
          ) ||

          (item.userTypeId === 2 && 
            (
              's'.includes(search.toLowerCase()) || 
              'st'.includes(search.toLowerCase()) || 
              'sta'.includes(search.toLowerCase()) || 
              'staf'.includes(search.toLowerCase()) || 
              'staff'.includes(search.toLowerCase())
            )
          ) ||

          (item.registrationStatusId === 1 && 
            (
              'n'.includes(search.toLowerCase()) || 
              'ne'.includes(search.toLowerCase()) || 
              'new'.includes(search.toLowerCase())
            )
          ) ||

          (item.registrationStatusId === 6 && 
            (
              'a'.includes(search.toLowerCase()) || 
              'ap'.includes(search.toLowerCase()) || 
              'app'.includes(search.toLowerCase()) || 
              'appr'.includes(search.toLowerCase()) || 
              'appro'.includes(search.toLowerCase()) ||
              'approv'.includes(search.toLowerCase()) || 
              'approve'.includes(search.toLowerCase()) || 
              'approved'.includes(search.toLowerCase())
            )
          );
      }

      return result;
    }

    customSort(items: any[], sortBy: string[], sortDesc: boolean[], locale: string) {
      // If the result is negative, a is sorted before b.
      // If the result is positive, b is sorted before a.
      // If the result is 0, no changes are done with the sort order of the two values.

      switch (sortBy[0]) {
        case 'status': 
          // admin then staff
          if (sortDesc[0]) {
            items.sort((a: LinkedUser, b: LinkedUser) => {
              let sort = 0;

              if (a.userTypeId > b.userTypeId) {
                sort = 1;
              } else if (b.userTypeId > a.userTypeId) {
                sort = -1;
              }
              return sort;
            });
          } else {
            items.sort((a: LinkedUser, b: LinkedUser) => {
              let sort = 0;

              if (a.userTypeId > b.userTypeId) {
                sort = -1;
              } else if (b.userTypeId > a.userTypeId) {
                sort = 1;
              }
              return sort;
            });
          }
          break;

        case 'name': 
          // {{ item.firstName + ' ' + item.lastName}}
          if (sortDesc[0]) {
            items.sort((a: LinkedUser, b: LinkedUser) => 
              (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName));
          } else {
            items.sort((a: LinkedUser, b: LinkedUser) => 
              (b.firstName + ' ' + b.lastName).localeCompare(a.firstName + ' ' + a.lastName));
          }
          break;

        case 'email': 
          if (sortDesc[0]) {
            items.sort((a: LinkedUser, b: LinkedUser) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()));
          } else {
            items.sort((a: LinkedUser, b: LinkedUser) => b.email.toLowerCase().localeCompare(a.email.toLowerCase()));
          }
          break;

        case 'cellphoneNumber': 
          // COMING:  {{ (item.cellphoneNumber) ? '+' + item.cellphoneCountryDialCode + ' ' + item.cellphoneNumber : '' }} 
          if (sortDesc[0]) {
            items.sort((a: LinkedUser, b: LinkedUser) => 
              (a.cellphoneNumber ? a.cellphoneNumber : '').toLowerCase().localeCompare((b.cellphoneNumber ? b.cellphoneNumber : '').toLowerCase()));
          } else {
            items.sort((a: LinkedUser, b: LinkedUser) => 
              (b.cellphoneNumber ? b.cellphoneNumber : '').toLowerCase().localeCompare((a.cellphoneNumber ? a.cellphoneNumber : '').toLowerCase()));
          }
          break;

        case 'lastLogin': 
          if (sortDesc[0]) {
            items.sort((a: LinkedUser, b: LinkedUser) => 
              (a.lastLogin ? a.lastLogin : '').toLowerCase().localeCompare((b.lastLogin ? b.lastLogin : '').toLowerCase()));
          } else {
            items.sort((a: LinkedUser, b: LinkedUser) => 
              (b.lastLogin ? b.lastLogin : '').toLowerCase().localeCompare((a.lastLogin ? a.lastLogin : '').toLowerCase()));
          }
          break;
      }

      return items;
    }

    async beforeMount() {
      await UserService.listUserTypes(false);
      this.listAll();
    }

    async listAll() {
      try {
        this.isLoading = true;
        const response: string = await UserService.listUsers(true);

        if (response.length > 0 && response !== 'No Data Found') {
          AppService.errorHandler(response);
        }

      } finally {
        this.isLoading = false;
      } 
    }

    getLastLogin(item: LinkedUser) {
      if (item.lastLogin) {
        return dayjs().from(item.lastLogin, true)  + ' ago';
      } else {
        return '';
      }
    }

    canResendInvite(item: LinkedUser) {
      // Can only resend the invite for Users with registrationStatusId === 1 (NEW) where at least 24hrs have passed since the User was created 
      return item.registrationStatusId === 1 && dayjs(item.updatedAt).isBefore(dayjs().add(-1, 'day'));
    }
  // #endregion

  // #region Functions required for the Item Dialog for Create
    itemDialogClose() {
      this.showItemDialog = false;

      setTimeout(() => {
        this.itemDialogAvailable = false;
      }, 200);
    }

    launchNewItemDialog() {
      this.clickedItem = {
        id: 0,
        userTypeId: 0,
        userType: '',
        registrationStatusId: 0,
        registrationStatus: '',
        suspended: false,
        updatedAt: '',

        firstName: '',
        lastName: '',
        email: '',
      };

      this.valid = false;
      this.title = 'Invite Additional Staff';

      if (this.$refs.itemForm) {
        (this.$refs.itemForm as any).resetValidation();
      }

      this.itemDialogAvailable = true;
      this.showItemDialog = true;
    }

    async doCreate() {
      try {
        this.clicked = true;

        const response = await UserService.invite( {
          firstName: this.clickedItem.firstName,
          lastName: this.clickedItem.lastName,
          email: this.clickedItem.email,
          userTypeId: this.clickedItem.userTypeId,
        });

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            await this.listAll();
            this.itemDialogClose();
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('User_Listing.vue - doCreate - 424 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.clicked = false;
      }
    }
  // #endregion

  // #region Functions required for the Information Dialog
    InformationDialog_cancel() {
      this.showInfoDialog = false;
      this.infoDialogTitle = '';
      this.infoDialogContent = '';

      setTimeout(() => {
        this.infoDialogAvailable = false;
      }, 200);
    }

    launchUserTypeInfoDialog() {
      this.infoDialogTitle = this.$store.getters['app/infoDialogs'].userType.title;
      this.infoDialogContent = this.$store.getters['app/infoDialogs'].userType.content;
      this.infoDialogAvailable = true;
      this.showInfoDialog = true;
    }
  // #endregion

  // #region Functions required for the Confirm Action Dialog for Delete / Suspend and Re-activate
    launchResendInviteItemDialog(item: LinkedUser) {
      this.clickedItem = item;

      this.action = 'REINVITE';
      this.dialogContent = 'You are about to resend the invitation to '
        + '<b>' + this.clickedItem.firstName + ' ' + this.clickedItem.lastName  + '</b> to join your team on eazigear.'
        + '<br/><br/> Are you sure?';

      this.confirmActionDialogAvailable = true;
      this.showConfirmActionDialog = true;
    }

    launchActivateItemDialog(item: LinkedUser) {
      this.clickedItem = item;

      this.action = 'ACTIVATE';
      this.dialogContent = 'You are about to <span style="font-weight: 700;color: #71BC78">activate</span> the eazigear account for '
        + '<span style="font-weight: 700">' + item.firstName + ' ' + item.lastName + '</span>.'
        + '<br/><br/> Are you sure?';

      this.confirmActionDialogAvailable = true;
      this.showConfirmActionDialog = true;
    }

    launchSuspendItemDialog(item: LinkedUser) {
      this.clickedItem = item;

      this.action = 'SUSPEND';
      this.dialogContent = 'You are about to <span style="font-weight: 700;color: #C01212 ">suspend</span> the eazigear account for '
        + '<span style="font-weight: 700">' + item.firstName + ' ' + item.lastName + '.'
        + '<br/><br/> Are you sure?';
      
      this.confirmActionDialogAvailable = true;
      this.showConfirmActionDialog = true;
    }

    launchDeleteItemDialog(item: LinkedUser) {
      this.clickedItem = item;

      this.action = 'DELETE';
      this.dialogContent = 'You are about to <span style="font-weight: 700; color: #C01212">delete</span> the eazigear account for '
        + '<span style="font-weight: 700">' + item.firstName + ' ' + item.lastName + '</span>.'
        + '<br/><br/> Are you sure?';

      this.confirmActionDialogAvailable = true;
      this.showConfirmActionDialog = true;
    }

    ConfirmActionDialog_cancel() {
      this.showConfirmActionDialog = false;

      setTimeout(() => {
        this.confirmActionDialogAvailable = false;
      }, 200);
    }

    async ConfirmActionDialog_doConfirmed() {
      if (this.action === 'REINVITE') {
        this.doResendInvite();
      } else if (this.action === 'SUSPEND') { 
        this.clickedItem.suspended = true;
        await this.doUpdate();
      } else if (this.action === 'ACTIVATE') {
        this.clickedItem.suspended = false;
        await this.doUpdate();
      } else if (this.action === 'DELETE') {
        this.doDelete();
      }
    }

    async doResendInvite() {
      try {
        this.confirmActionDelay = true;
        const response = await UserService.resendInvite({id: this.clickedItem.id});
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            await this.listAll();
            this.ConfirmActionDialog_cancel();
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('User_Listing.vue - doResendInvite - 787 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.confirmActionDelay = false;
      }
    }

    async doUpdate() {
      try {
        this.confirmActionDelay = true;

        const response = await UserService.update({
          id: this.clickedItem.id,
          suspended: this.clickedItem.suspended,
        });

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            await this.listAll();
            this.ConfirmActionDialog_cancel();
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('User_Listing.vue - doUpdate - 424 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.confirmActionDelay = false;
      }
    }

    async doDelete() {
      try {
        this.confirmActionDelay = true;
        const response = await UserService.delete({id: this.clickedItem.id});
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            await this.listAll();
            this.ConfirmActionDialog_cancel();
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.logSupportDebug('User_Listing.vue - doDelete - 745 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.confirmActionDelay = false;
      }
    }
  // #endregion
}
