

















































// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ConfirmActionDialog extends Vue {

  // Makes a "dialogContent" a component prop with the default value of ''
  @Prop({default: ''})
  dialogContent!: string;

  @Prop({default: false})
  confirmActionDelay!: boolean;

}
