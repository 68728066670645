/**
 *   This is a service for a specific store module which uses the api.ts Axios instance as a base.
 *   We separate our files for easy managment and readibility.
 *   We export this as a service to be used in other areas of the application.
 */

import Api from '@/services/api';
import AppService from '@/services/app';
import Store from '@/store/index';

export default {

  async listUserTypes(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['users/userTypes'].length < 1) {
      try {
        const response = await Api().get('/user/list_types');
        
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            Store.dispatch('users/setUserTypes', {
              userTypes: [],
            });
            return response.data.message;
          } else {
            Store.dispatch('users/setUserTypes', {
              userTypes: response.data,
            });
            return '';
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        Store.dispatch('users/setUserTypes', {
          users: [],
        });
        if (error.response) {
          return error.response.statusText;
        } else {
          AppService.logSupportDebug('users.ts - listUserTypes - 43 - ' + error);
          return Store.getters['app/messages'].couldNotConnect;
        }
      } 
    } else {
      // this data is ready to access in the Store
      return '';
    }
  },

  async listUsers(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['users/users'].length < 1) {
      try {
        const response = await Api().get('/user/list_all');
        
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            Store.dispatch('users/setUsers', {
              users: [],
            });
            return response.data.message;
          } else {
            Store.dispatch('users/setUsers', {
              users: response.data,
            });
            return '';
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        Store.dispatch('users/setUsers', {
          users: [],
        });
        if (error.response) {
          return error.response.statusText;
        } else {
          AppService.logSupportDebug('users.ts - listUsers - 83 - ' + error);
          return Store.getters['app/messages'].couldNotConnect;
        }
      } 
    } else {
      // this data is ready to access in the Store
      return '';
    }
  },

  invite(params: { 
    firstName: string,
    lastName: string,
    email: string,
    userTypeId: number,
  }) {
    return Api().post('/user/invite', params);
  },

  resendInvite(params: { 
    id: number,
   }) {
    return Api().post('/user/resend', params);
  },

  update(params: {
    id: number,
    suspended: boolean,
  }) {
    return Api().post('/user/update', params);
  },

  delete(params: { 
    id: number,
   }) {
    return Api().post('/user/delete', params);
  },

};
